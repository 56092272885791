import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const Sample_Item2 = () => {
  const [userLanguage, setUserLanguage] = useState('en');

  useEffect(() => {
    // 클라이언트 사이드에서만 navigator 객체를 참조합니다.
    if (typeof navigator !== 'undefined') {
      const language = navigator.language || navigator.userLanguage;
      setUserLanguage(language);
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h1">Item 2</Typography>
      <Typography>This is the Item 2 page.</Typography>
      <div style={{ width: '100%', height: '500px' }}>
        <iframe
          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.920474509374!2d126.9780!3d37.5665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2f0f1a1a1a1%3A0x1a1a1a1a1a1a1a1a!2sSeoul!5e0!3m2!1sen!2skr!4v1633072800000!5m2!1sen!2skr&hl=${userLanguage}`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe>
      </div>
    </Box>
  );
};

export default Sample_Item2;